import { useField } from 'formik'

// Library
import Input from 'library/Input'

// Styles
import { Wrapper, Label, Error } from './style'

const InputField = ({ label, textarea, dark, ...props }) => {
  const [field, meta, helpers] = useField(props)

  return (
    <>
      <Wrapper>
        <Label dark={dark}>
          {label}
          <Input
            as={textarea && 'textarea'}
            dark={dark}
            {...field}
            {...props}
          />
        </Label>
      </Wrapper>
      {meta.touched && meta.error ? (
        <Error className="error">{meta.error}</Error>
      ) : null}
    </>
  )
}

export default InputField
