import styled from '@emotion/styled'

const Input = styled.input`
  width: 100%;
  max-width: 448px;
  margin: 0 auto;
  padding: 0.875rem;

  outline: none;
  background-color: transparent;
  border: 1px solid
    ${({ dark, theme }) => (dark ? theme.colors.primaryDarkestAlt : '#fff')};

  font: ${({ theme }) => theme.typography.paragraphMinor};
  color: ${({ dark, theme }) =>
    dark ? theme.colors.primaryDarkestAlt : '#fff'};

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font: ${({ theme }) => theme.typography.paragraphDeskMinor};
  }
`

export default Input
