import styled from '@emotion/styled'

const Wrapper = styled.section`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  background-color: ${({ theme, dark }) =>
    dark ? theme.colors.primaryDarkestAlt : '#fff'};

  button {
    margin-top: 2rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
      margin-top: 1.777777777777778rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding-top: 2.888888888888889rem;
    padding-bottom: 4.555555555555556rem;
  }
`

const Title = styled.h1`
  max-width: 448px;
  margin: 0 auto;

  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primaryDark};

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    max-width: none;

    font-size: 16.25vw;
    text-align: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font: ${({ theme }) => theme.typography.h1Desk};
  }
`

const Feedback = styled.div`
  width: 100%;
  max-width: 448px;
  margin: 0.5rem auto 0;

  font-size: 0.875rem;
  letter-spacing: 0.64px;
  line-height: 1.285;
  text-align: center;

  color: ${({ error }) => (error ? '#FC2020' : '#2AD51E')};

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin: 1rem auto 0;

    font-size: 1rem;
    line-height: 1.1875;
  }
`

export { Wrapper, Title, Feedback }
