import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Button = styled.button`
  display: block;

  width: 100%;
  max-width: 448px;
  margin: 0 auto;
  padding: 0.875rem 0;

  border: 1px solid;
  border-color: ${({ black, theme }) =>
    black ? theme.colors.primaryDarkest : '#FFF'};
  background-color: ${({ black, theme }) =>
    black ? theme.colors.primaryDarkest : '#FFF'};
  color: ${({ black, theme }) =>
    black ? '#FFF' : theme.colors.primaryDarkest};

  font: ${({ theme }) => theme.typography.paragraphMinor};
  text-transform: uppercase;

  cursor: pointer;
  outline: transparent;
  transition: all 0.225s ease-in;

  &:hover,
  &:disabled {
    background: ${({ black, theme }) =>
      black ? '#FFF' : theme.colors.primaryDarkestAlt};
    color: ${({ black, theme }) =>
      black ? theme.colors.primaryDarkestAlt : '#FFF'};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: 0.7777777777777778rem 0;

    font: ${({ theme }) => theme.typography.paragraphDeskMinor};
  }
`

Button.defaultProps = {
  black: false
}

Button.propTypes = {
  black: PropTypes.bool
}

export default Button
