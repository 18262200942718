import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Axios from 'axios'

// Constants
import { CONTACT_URL } from 'constants/tags.js'

// Library
import Container from 'library/Container'
import Button from 'library/Button'

// Components
import InputField from 'components/InputField'
import SectionHeader from 'components/SectionHeader'

// Styles
import { Wrapper, Feedback } from './style.js'

const Contact = ({ contact, section, dark = true }) => {
  const formRef = useRef(null)

  const {
    title,
    name_label,
    name_placeholder,
    message_label,
    message_placeholder,
    email_label,
    email_placeholder,
    button_label
  } = contact?.data

  const initialValues = {
    nome: '',
    email: '',
    mensagem: ''
  }

  const schema = Yup.object({
    nome: Yup.string()
      .min(3, 'Nome deve ter no mínimo 3 letras.')
      .required('Campo obrigatório.'),
    email: Yup.string()
      .email('E-mail inválido.')
      .required('Campo obrigatório.'),
    mensagem: Yup.string().required('Campo obrigatório.')
  })

  const handleSubmit = (
    values,
    { setSubmitting, resetForm, setErrors, setStatus, setValues, setTouched }
  ) => {
    const body = new FormData(formRef.current)

    Axios.post(CONTACT_URL, body)
      .then(res => {
        // gtag.conversion(AW_TRACKING_CONVERSION)
        // gtag.event({ action: 'conversão', category: 'Contato' })
        // fbq.track({ event: 'Contact' })

        resetForm()
        setStatus({ success: 'Mensagem enviada com sucesso!' })
      })
      .catch(errors => {
        if (errors.response.data && errors.response.data.errors) {
          setErrors(errors.response.data.errors)
          setStatus({
            error:
              '<span>Algo deu errado. <br/>Verifique os campos acima.</span>'
          })
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Wrapper dark={dark}>
      <Container>
        <SectionHeader noPadding dark title={title} isSectionTitle={section} />
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ status, isSubmitting }) => (
            <Form ref={formRef}>
              <InputField
                dark={!dark}
                name="nome"
                type="text"
                label={name_label}
                placeholder={name_placeholder}
              />
              <InputField
                dark={!dark}
                name="email"
                type="email"
                label={email_label}
                placeholder={email_placeholder}
              />
              <InputField
                dark={!dark}
                textarea
                name="mensagem"
                type="text"
                label={message_label}
                placeholder={message_placeholder}
                rows="7"
              />
              <Button type="submit" disabled={isSubmitting} black={!dark}>
                {isSubmitting ? 'Enviando' : button_label}
              </Button>

              {status?.success && (
                <Feedback>
                  <span>{status.success}</span>
                </Feedback>
              )}
              {status?.error && (
                <Feedback
                  error
                  dangerouslySetInnerHTML={{ __html: status.error }}
                />
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </Wrapper>
  )
}

Contact.propTypes = {
  contact: PropTypes.object.isRequired,
  section: PropTypes.bool
}

Contact.defaultProps = {
  section: false
}

export default Contact
