import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;

  padding-top: 0.5rem;

  &:first-of-type {
    padding-top: 1rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
      padding-top: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding-top: 1.333333333333333rem;
  }
`

const Label = styled.label`
  width: 100%;
  max-width: 448px;
  margin: 0 auto;

  letter-spacing: 0.64px;
  color: ${({ dark, theme }) =>
    dark ? theme.colors.primaryDarkestAlt : '#fff'};

  > input,
  textarea {
    margin-top: 0.375rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
      margin-top: 0.4444444444444444rem;
    }
  }
`

const Error = styled.div`
  width: 100%;
  max-width: 448px;
  margin: 0.5rem auto 0;

  color: #f8eb14;

  font-size: 0.7rem;
  font-style: italic;
  letter-spacing: 0.6px;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font-size: 0.8rem;
  }
`

export { Wrapper, Label, Error }
